import "./i18n/config.ts";
import { Router as Router_, Route, useSearch } from "wouter";
import Home from "@/components/Home";
import Company from "@/components/Company";
import RegisterVisitor from "@/components/RegisterVisitor";
import RegisteredSuccess from "@/components/RegisterSuccess.tsx";
import RegisterFail from "@/components/RegisterFail.tsx";
import ErrorBoundary from "./components/ErrorBoundary.tsx";

export default function Router() {
  const search = useSearch();
  const localEntranceId = localStorage.getItem("entranceId");
  const queryEntranceId = search.slice(11);
  if (
    !localEntranceId ||
    (queryEntranceId && localEntranceId !== queryEntranceId)
  )
    localStorage.setItem("entranceId", queryEntranceId);

  return (
    <ErrorBoundary>
      <Router_>
        <Route path="/" component={Home} />
        <Route path="/company/:id" component={Company} />
        <Route path="/registervisitor" component={RegisterVisitor} />
        <Route path="/registeredsuccess" component={RegisteredSuccess} />
        <Route path="/registeredfail" component={RegisterFail} />
      </Router_>
    </ErrorBoundary>
  );
}
