import { Button } from "@app-components";
import styles from "@/styles/FrontDeskButton.module.scss";
import { useTranslation } from "react-i18next";
import { useVisitorStore, VisitType } from "@/lib/store";
import { useLocation } from "wouter";

export default function FrontDeskButton() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { setVisitType } = useVisitorStore();

  const registration = () => {
    setVisitType(VisitType.COMPANY);
    setLocation("/registervisitor");
  };

  return (
    <div className={styles.container}>
      <p>{t("CallFrontDesk.HelperText")}</p>
      <Button large onClick={registration}>
        {t("CallFrontDesk.Button")}
      </Button>
    </div>
  );
}
