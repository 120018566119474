import { useEffect, PropsWithChildren } from "react";
import * as Sentry from "@sentry/react";
import { env } from "@/lib/utils";

export default function MonitoringProvider({ children }: PropsWithChildren) {
  useEffect(() => {
    if (env("VITE_ENVIRONMENT") === "dev") return;
    if (Sentry.isInitialized()) return;
    Sentry.init({
      dsn: env("VITE_SENTRY_DSN"),
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }, []);
  return children;
}
