import styles from "@/styles/RegisterSuccess.module.scss";
import { Button, Icon } from "@app-components";
import WelcomeBadge from "@/components/WelcomeBadge";
import { motion } from "framer-motion";
import { useLocation } from "wouter";
import { useVisitorStore, VisitType } from "@/lib/store";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { BASE_URL, MORE_INFO_URL } from "@/lib/constants";

export default function RegisteredSuccess() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const [isSending, setIsSending] = useState(false);
  const [infoSent, setInfoSent] = useState(false);
  const {
    visitType,
    hostPerson,
    hostCompany,
    visitor,
    hostGroup,
    preRegisteredPerson,
    resetWizard,
  } = useVisitorStore();

  const redirectToHome = (devOverride = false) => {
    if (process.env.NODE_ENV !== "development" || devOverride) {
      setLocation(`/?entranceId=${localStorage.getItem("entranceId")}`);
      resetWizard();
    }
  };

  const sendMoreInfo = async () => {
    const requestBody = {
      recipientsPhoneNumber: visitor.phoneNumber,
      messageBody: t("MoreInfoMessage", { url: MORE_INFO_URL }),
    };
    try {
      setIsSending(true);
      const response = await fetch(`${BASE_URL}/sendSmsUnauth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setIsSending(false);
        setInfoSent(true);
      }
    } catch (error) {
      setIsSending(false);
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        className={styles.mainText}
      >
        <h1>
          {visitType === VisitType.PERSON ? (
            <Trans i18nKey="Registration.Success.Header">
              <strong>
                <>
                  {{
                    hostFullName: `${hostPerson.firstName} ${hostPerson.lastName}`,
                  }}
                </>
              </strong>
              text
              <strong>
                <>{{ hostCompanyName: hostCompany!.buildingTenantName }}</>
              </strong>
              text
            </Trans>
          ) : visitType === VisitType.PRE_REG && !!preRegisteredPerson ? (
            t("Registration.Success.Group.RegisteredAttendee.Header")
          ) : visitType === VisitType.PRE_REG && !preRegisteredPerson ? (
            <Trans i18nKey="Registration.Success.Group.NonRegisteredAttendee.Header">
              Text{" "}
              <strong>
                <>{{ groupName: hostGroup?.eventName }}</>
              </strong>{" "}
              text
            </Trans>
          ) : (
            <Trans i18nKey="Registration.Success.Company.Header">
              Text
              <strong>
                <>{{ hostCompanyName: hostCompany!.buildingTenantName }}</>
              </strong>
              text
            </Trans>
          )}
        </h1>
        <p>
          {visitType === VisitType.PERSON ? (
            t("Registration.Success.Instructions", {
              hostFirstName: hostPerson.firstName,
            })
          ) : visitType === VisitType.PRE_REG && !!preRegisteredPerson ? (
            t("Registration.Success.Group.RegisteredAttendee.Instructions")
          ) : visitType === VisitType.PRE_REG && !preRegisteredPerson ? (
            <Trans i18nKey="Registration.Success.Group.NonRegisteredAttendee.Instructions">
              Text <strong>text</strong> text
            </Trans>
          ) : (
            t("Registration.Success.Company.Instructions")
          )}
        </p>
        {hostGroup?.eventDescription && (
          <p>
            {t("Registration.Success.Group.GoodToKnow")}: <br />
            <strong style={{ fontSize: "1.5em" }}>
              "{hostGroup.eventDescription}"
            </strong>
          </p>
        )}
        <Button dark large onClick={() => redirectToHome(true)}>
          {t("Registration.Success.Finish")}
        </Button>
      </motion.div>
      <div className={styles.bottomText}>
        <WelcomeBadge />
        {infoSent ? (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--wlcm-spacing-xs)",
            }}
          >
            <Icon name="done" /> {t("Registration.Success.MoreInfoSent")}
          </p>
        ) : (
          <Button
            dark
            secondary
            large
            onClick={sendMoreInfo}
            isLoading={isSending}
          >
            {t("Registration.Success.MoreInfo")}
          </Button>
        )}
      </div>
      <motion.div
        key={(isSending && !infoSent) || infoSent ? "restart-timer" : "timer"}
        className={styles.countdownBar}
        initial={{ x: 0 }}
        animate={{ x: "-100%" }}
        transition={{ duration: 12 }} // duration is in seconds
        onAnimationComplete={() => redirectToHome()}
      ></motion.div>
    </div>
  );
}
