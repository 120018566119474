import { useQuery } from "@tanstack/react-query";
import { Company } from "@/types";
import { BASE_URL } from "@/lib/constants";

export const useCompanies = (entranceId: string) =>
  useQuery({
    queryKey: ["companies", entranceId],
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}/getCompanies?entranceId=${entranceId}`,
      );
      if (response.ok) {
        return (await response.json()) as unknown as Company[];
      } else {
        throw new Error(
          `Failed to fetch companies ${JSON.stringify(await response.json())}`,
        );
      }
    },
    enabled: !!entranceId,
  });
