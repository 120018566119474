import styles from "@/styles/Header.module.scss";
import ButtonRow from "@/components/ButtonRow";
import { useVisitorStore, VisitType } from "@/lib/store";
import HostCard from "@/components/HostCard";
import { useTranslation } from "react-i18next";
import CompanyLogo from "@/components/CompanyLogo/CompanyLogo";
import { motion } from "framer-motion";

interface Props {
  step?: number; // Step 1: CompanySelector, Step 2: PersonSelector, Step 3: RegisterVisitor
}

export default function Header({ step }: Props) {
  const { hostCompany, hostPerson, hostGroup, visitType } = useVisitorStore();
  const { t } = useTranslation();
  const hostName = `${hostPerson.firstName} ${hostPerson.lastName}`;
  if (!hostCompany) {
    throw new Error("Crucial data is missing");
  }

  return (
    <motion.div
      className={styles.header}
      initial={{ height: "auto" }}
      animate={{ height: "auto" }}
      transition={{ duration: 0.3 }}
    >
      <ButtonRow />
      <div className={styles.selectedCompany}>
        <CompanyLogo
          name={hostCompany.buildingTenantName}
          src={hostCompany.companyLogoImgUrl}
        />
        <h1>{hostCompany.buildingTenantName}</h1>
      </div>
      {step === 3 && (
        <motion.div
          className={styles.selectedPerson}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          {visitType === VisitType.PRE_REG ? (
            <>
              <p>{t("YouAreAttending")}</p>
              <HostCard secondary hostName={hostGroup?.eventName || ""} />
            </>
          ) : (
            <>
              <p>{t("YouAreVisiting")}</p>
              <HostCard
                secondary
                hostName={
                  visitType === VisitType.PERSON ? hostName : t("CompanyAdmin")
                }
              />
            </>
          )}
        </motion.div>
      )}
    </motion.div>
  );
}
