import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Router from "./Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@/styles/index.scss";
import InactivityWarning from "./components/InactivityWarning";
import MonitoringProvider from "./components/MonitoringProvider";

const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <MonitoringProvider>
        <InactivityWarning />
        <Router />
      </MonitoringProvider>
    </QueryClientProvider>
  </StrictMode>,
);
