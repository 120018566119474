import styles from "@/styles/InactivityWarning.module.scss";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@app-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import { useVisitorStore } from "@/lib/store";
import { usePathname } from "wouter/use-browser-location";

const WHITELIST = ["/", "/en"];
const OVERLAY_AFTER_SECONDS = 30;
const REDIRECT_AFTER_SECONDS = 20;

export default function InactivityWarning() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { resetWizard } = useVisitorStore();
  const pathname = usePathname();
  let overlayTimeout: NodeJS.Timeout | undefined;
  let countdownInterval: NodeJS.Timeout | undefined;
  const [showOverlay, setShowOverlay] = useState(false);
  const [countdown, setCountdown] = useState(REDIRECT_AFTER_SECONDS);

  const backToStart = () => {
    resetWizard();
    setLocation("/?entranceId=" + localStorage.getItem("entranceId"));
  };

  const restartTimer = () => {
    if (process.env.NODE_ENV === "development") return; // disable warning in DEV
    setShowOverlay(false);
    if (overlayTimeout) {
      clearTimeout(overlayTimeout);
    }
    overlayTimeout = setTimeout(() => {
      setShowOverlay(true);
    }, 1000 * OVERLAY_AFTER_SECONDS);
  };

  // Handle showing/hiding overlay
  useEffect(() => {
    if (WHITELIST.includes(pathname)) {
      setShowOverlay(false);
      return;
    }
    restartTimer();
    window.addEventListener("keypress", restartTimer);
    window.addEventListener("pointerup", restartTimer);
    return () => {
      if (overlayTimeout) {
        clearTimeout(overlayTimeout);
      }
      window.removeEventListener("keypress", restartTimer);
      window.removeEventListener("pointerup", restartTimer);
    };
  }, [pathname]);

  // Handle countdown to redirect after overlay appears
  useEffect(() => {
    if (showOverlay) {
      // Blur all inputs to dismiss on-screen keyboard
      Array.prototype.slice
        .call(document.getElementsByTagName("input"))
        .forEach((field) => field.blur());
      // Reset countdown
      setCountdown(REDIRECT_AFTER_SECONDS);
      countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            if (process.env.NODE_ENV !== "development") backToStart();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearTimeout(overlayTimeout);
      clearInterval(countdownInterval);
    };
  }, [showOverlay]);

  return (
    <AnimatePresence>
      {showOverlay && (
        <motion.div
          className={styles.container}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className={styles.topContainer}>
            <h1>{t("Inactivity.Header")}</h1>
            <p>{t("Inactivity.Text")}</p>
            <p className={styles.counter}>{countdown}</p>
            <p>{t("Inactivity.Seconds")}</p>
          </div>
          <div className={styles.bottomContainer}>
            <p>{t("Inactivity.QuitNow")}</p>
            <Button dark large onClick={backToStart}>
              {t("Inactivity.QuitBtn")}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
