import styles from "./Card.module.scss";

interface ICardProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  onClick?: (e?: any) => void;
}

export default function Card({ children, width, height, onClick }: ICardProps) {
  const cardStyle = {
    width,
    height,
  };
  return (
    <div onClick={onClick} className={styles.card} style={cardStyle}>
      {children}
    </div>
  );
}
