import { Timestamp } from "@/types";

export const formatTimestamp = (timestamp: Timestamp): string => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }

  const date = new Date(timestamp._seconds * 1000);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const env = (key: string) => {
  const value = import.meta.env[key];
  if (!value) {
    console.error(`Env ${key} not found`);
    throw new Error(`Env ${key} not found`);
  }
  return value;
};

export const validatePhoneNumber = (number: string) => {
  if (isNorwegianNumber(number)) {
    const cleanedNumber = cleanPhoneNumber(number);
    if (
      (cleanedNumber.includes("+47") && cleanedNumber.length === 11) ||
      (!cleanedNumber.includes("+47") && cleanedNumber.length === 8)
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (number.length >= 8 && number.includes("+")) {
    return true;
  }

  return false;
};

export const cleanPhoneNumber = (number: string) => {
  if (!number) {
    return "";
  }
  return number.replace(/[^\d+]/g, "");
};

export const isNumberMissingCountryCode = (number: string) => {
  //this is only needed for foreign numbers. We can only assume that numbers with more than 8 digits are foreign.
  return number.length > 8 && !number.includes("+");
};

export const isNorwegianNumber = (number: string) => {
  return (
    number.substring(0, 3) === "+47" ||
    (!number.includes("+") && number.length == 8)
  );
};

export const addNorwegianCountryCodeToNorwegianNumbers = (number: string) => {
  if (isNorwegianNumber(number)) {
    if (!number.includes("+47")) {
      return "+47" + number;
    }
  }
  return number;
};
