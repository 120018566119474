import styles from "@/styles/Loader.module.scss";
export default function Loader() {
  return (
    <div className={styles.ldsGrid}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
