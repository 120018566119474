import { useTranslation } from "react-i18next";
import { Button } from "@app-components";

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "no" ? "en" : "no");
  };

  return (
    <div>
      {i18n.language === "no" ? (
        <Button dark large onClick={changeLanguage}>
          {t("English")}
        </Button>
      ) : (
        <Button dark large onClick={changeLanguage}>
          {t("Norwegian")}
        </Button>
      )}
    </div>
  );
}
