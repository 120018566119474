import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./messages/en.json";
import no from "./messages/no.json";

i18next.use(initReactI18next).init({
  lng: "no",
  debug: false,
  resources: {
    en: {
      translation: en,
    },
    no: {
      translation: no,
    },
  },
});
