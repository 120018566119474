import { useQuery } from "@tanstack/react-query";
import { Entrance } from "@/types";
import { BASE_URL } from "@/lib/constants";

export const useEntrance = (entranceId: string) =>
  useQuery({
    queryKey: ["entrance", entranceId],
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}/getEntrance?entranceId=${entranceId}`,
      );
      if (response.ok) {
        return (await response.json()) as unknown as Entrance[];
      } else {
        throw new Error(
          `Failed to fetch companies ${JSON.stringify(await response.json())}`,
        );
      }
    },
    enabled: !!entranceId,
  });
