import styles from "@/styles/Home.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { Company } from "@/types";
import { useCompanies, useEntrance } from "@/hooks";
import { Icon, InputField } from "@app-components";
import Card from "@/components/Card/Card";
import CompanyLogo from "@/components/CompanyLogo/CompanyLogo";
import LanguageSelector from "@/components/LanguageSelector";
import Loader from "@/components/Loader";
import { useVisitorStore } from "@/lib/store";
import { useBuilding } from "@/hooks/getBuilding";

export default function Home() {
  const { t, i18n } = useTranslation();
  const entranceId = localStorage.getItem("entranceId") as string;
  const { setEntranceName, setBuildingName } = useVisitorStore();
  const { isLoading: isLoadingBuilding, data: building } = useBuilding();
  const { isLoading, data, isSuccess, error } = useEntrance(entranceId);
  const { isLoading: isLoadingEntrance } = useCompanies(entranceId);

  useEffect(() => {
    if (data && building) {
      setBuildingName(building.displayName);
      setEntranceName(
        data[0].entranceName[i18n.language === "en" ? "en" : "no"],
      );
    }
  }, [data, building]);

  if (!entranceId) {
    return (
      <p className={styles.entranceIdMissing}>
        The URL is missing a valid entrance ID
      </p>
    );
  }
  if (isLoadingBuilding || isLoading || isLoadingEntrance)
    return (
      <div className="center">
        <Loader />
      </div>
    );
  if (!isSuccess) throw new Error("Couldn't load entrance " + error);
  return (
    <main className={styles.main}>
      <div className={styles.topBar}>
        <p>
          <Icon name="location_on" />
          {building?.displayName},{" "}
          {data[0].entranceName[i18n.language === "en" ? "en" : "no"]}
        </p>
        <LanguageSelector />
        <h1>
          <strong>{t("Welcome.Strong")}</strong>
          {t("Welcome.Rest")}
        </h1>
      </div>
      <CompanySelector entranceId={entranceId} />
    </main>
  );
}

const CompanySelector = ({ entranceId }: { entranceId: string }) => {
  const { t } = useTranslation();
  const { data: companies, isSuccess, error } = useCompanies(entranceId);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);

  useEffect(() => {
    if (searchTerm && companies) {
      setFilteredCompanies(
        companies.filter((company) =>
          company.buildingTenantName
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredCompanies(companies || []);
    }
  }, [searchTerm, companies]);

  if (!isSuccess) {
    throw new Error("Could not get companies " + error);
  }

  const showSearch = companies.length > 6;

  return (
    <div className={styles.companiesContainer}>
      {showSearch && (
        <div className={styles.inputContainer}>
          <InputField
            large
            compact
            hideLabel
            label={t("SearchForCompany")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon="search"
            inputBgColor="var(--wlcm-color-white)"
            placeholder={t("SearchForCompany") + "..."}
          />
        </div>
      )}
      {searchTerm.length && !filteredCompanies.length ? (
        <div className={styles.noResultsText}>
          <h2>{t("CompaniesNoResults.Line1")}</h2>
          <p>{t("CompaniesNoResults.Line2")}</p>
        </div>
      ) : (
        <div
          className={styles.grid}
          style={{ paddingTop: showSearch ? "" : "var(--wlcm-spacing-md)" }}
        >
          {filteredCompanies.map((company, index) => (
            <CompanyCard key={index} company={company} />
          ))}
        </div>
      )}
    </div>
  );
};

const CompanyCard = ({ company }: { company: Company }) => {
  const [, setLocation] = useLocation();
  const { setSelectedCompany } = useVisitorStore();

  const selectCompany = () => {
    const selectedCompany = {
      ...company,
      name: company.buildingTenantName,
      companyId: company.buildingTenantId,
      logo: company.companyLogoImgUrl,
    };
    setSelectedCompany(selectedCompany);
    setLocation(`/company/${company.buildingTenantId}`);
  };

  return (
    <Card onClick={selectCompany} height="279px" width="100%">
      <CompanyLogo
        name={company.buildingTenantName}
        src={company.companyLogoImgUrl}
      />
      {company.buildingTenantName}
    </Card>
  );
};
