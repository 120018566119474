import { useQuery } from "@tanstack/react-query";
import { PreRegistrationGroup } from "@/types";
import { BASE_URL } from "@/lib/constants";

export const preRegistrationOptions = (companyId: string) => ({
  queryKey: ["pre-registrations", companyId],
  queryFn: async () => {
    const response = await fetch(
      `${BASE_URL}/visit/getPreRegistrations?companyId=${companyId}`,
    );
    if (response.ok) {
      return (await response.json())
        .groups as unknown as PreRegistrationGroup[];
    } else {
      throw new Error(
        `Failed to fetch companies ${JSON.stringify(await response.json())}`,
      );
    }
  },
});

export const usePreRegistrations = (companyId: string) =>
  useQuery({
    ...preRegistrationOptions(companyId),
    enabled: !!companyId,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
