import React from "react";
import { Button } from "@app-components";
import { useTranslation } from "react-i18next";
import styles from "@/styles/RegisterFail.module.scss";
import { motion } from "framer-motion";
import { useLocation } from "wouter";

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  t: (key: string) => string;
  setLocation: (loc: string) => void;
}

interface State {
  hasError: boolean;
}

const withEnhancements = (Component: typeof BasicBoundary) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function WrappedComponent(props: any) {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setLocation] = useLocation();

    return <Component {...props} t={t} setLocation={setLocation} />;
  };
};

class BasicBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    /* logErrorToMyService(error, info.componentStack); */
    console.log(info);
  }

  render() {
    const { t, setLocation } = this.props;

    const hardRefreshApp = (devOverride = false) => {
      if (process.env.NODE_ENV !== "development" || devOverride) {
        setLocation(`/?entranceId=${localStorage.getItem("entranceId")}`);
        window.location.reload();
      }
    };

    if (this.state.hasError) {
      // If a specific fallback UI is provided, return that
      // Otherwise show default fallback UI
      return (
        this.props.fallback || (
          <>
            <div className={styles.container}>
              <div className={styles.mainText}>
                <h1>
                  <strong>{t("ErrorBoundary.Header")}</strong>
                </h1>
                <p>{t("ErrorBoundary.Text")}</p>
                <Button dark large onClick={() => hardRefreshApp(true)}>
                  {t("ErrorBoundary.Reload")}
                </Button>
              </div>
              <motion.div
                className={styles.countdownBar}
                initial={{ x: 0 }}
                animate={{ x: "-100%" }}
                transition={{ duration: 12 }}
                onAnimationComplete={() => hardRefreshApp()}
              ></motion.div>
            </div>
          </>
        )
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary = withEnhancements(BasicBoundary);
export default ErrorBoundary;
