import styles from "@/styles/WelcomeBadge.module.scss";
import Logo from "@/assets/welcomeLogoBlack.svg";
import { useTranslation } from "react-i18next";

export default function WelcomeBadge() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img src={Logo} alt="Welcome Workdays Logo" width={76} height={42} />
      <p>
        {t("SystemMadeBy")} <br />
        <strong>Welcome Workdays</strong>
      </p>
    </div>
  );
}
