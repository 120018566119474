import styles from "./CompanyLogo.module.scss";
import { useEffect, useState } from "react";
import { Suspense } from "react";

interface Props {
  name: string;
  src: string;
}

export default function CompanyLogo({ name, src }: Props) {
  const [imageError, setImageError] = useState(false);
  const [fallbackColor, setFallbackColor] = useState("");

  const colors = ["green", "blue", "purple"];

  useEffect(() => {
    // Use name.length so fallback color stays consistent across pages in app
    const index = name.length % 3; // num between 0-2
    setFallbackColor(colors[index]);
  }, [imageError]);

  return (
    <div className={styles.imageWrapper}>
      <Suspense></Suspense>
      {imageError ? (
        <div className={`${styles.fallback} ${styles[fallbackColor]}`}>
          {name.charAt(0)}
        </div>
      ) : (
        <img
          src={src}
          alt={`${name} logo`}
          width="162"
          height="122"
          className={styles.image}
          onError={(e) => {
            setImageError(true);
            console.log(e);
          }}
        />
      )}
    </div>
  );
}
