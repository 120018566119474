import { Button } from "@app-components";
import LanguageSelector from "@/components/LanguageSelector";
import styles from "@/styles/ButtonRow.module.scss";
import { useLocation } from "wouter";
import { useVisitorStore } from "@/lib/store";
import { useTranslation } from "react-i18next";

export default function ButtonRow() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { resetWizard } = useVisitorStore();

  const cancelRegistration = () => {
    resetWizard();
    setLocation(`/?entranceId=${localStorage.getItem("entranceId")}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <Button large dark icon="arrow_back" onClick={() => history.back()}>
          {t("Back")}
        </Button>
        <Button onClick={cancelRegistration} secondary large>
          {t("Cancel")}
        </Button>
      </div>
      <LanguageSelector />
    </div>
  );
}
