import { Icon } from "@app-components";
import styles from "@/styles/PersonCard.module.scss";
import { useVisitorStore, VisitType } from "@/lib/store";

interface Props {
  hostName: string;
  secondary?: boolean;
  onClick?: () => void;
}

export default function HostCard({ hostName, secondary, onClick }: Props) {
  const { visitType } = useVisitorStore();

  return (
    <div
      onClick={onClick}
      className={`${styles.card} ${secondary && styles.secondary}`}
    >
      <div>
        {visitType === VisitType.PRE_REG ? (
          <Icon name="group" />
        ) : (
          <Icon name="person" />
        )}
      </div>
      <p>{hostName}</p>
    </div>
  );
}
