import { useQuery } from "@tanstack/react-query";
import { Person } from "@/types";
import { BASE_URL } from "@/lib/constants";

export const useUsers = (companyId: string) =>
  useQuery({
    queryKey: ["users", companyId],
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}/getUsers?companyId=${companyId}`,
      );
      if (response.ok) {
        return (await response.json()) as unknown as Person[];
      } else {
        throw new Error(
          `Failed to fetch companies ${JSON.stringify(await response.json())}`,
        );
      }
    },
    enabled: !!companyId,
  });
