import { useQuery } from "@tanstack/react-query";
import { Building } from "@/types";
import { BASE_URL } from "@/lib/constants";

export const useBuilding = () =>
  useQuery({
    queryKey: ["building"],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/getBuildingName`);
      if (response.ok) {
        return (await response.json()) as unknown as Building;
      } else {
        throw new Error(
          `Failed to fetch building info ${JSON.stringify(await response.json())}`,
        );
      }
    },
  });
