import { env } from "./utils";

type Environment = "dev" | "preview" | "prod";

export const MORE_INFO_URL = "www.welcomeworkdays.com";
export const NUMERIC_REGEX = /^[0-9+]+$/;
export const MESSAGE_REGEX = /^[\p{L}\p{N}\s!'\-&()?,."]*$/u;
export const NAME_REGEX = /^[\p{L}\s\-&.]*$/u;
export const COMPANY_REGEX = /^[\p{L}\p{N}\s\-&.]*$/u;

const ENV_DOMAINS = {
  dev: `https://portal-${env("VITE_FB_PROJECT")}.web.app`,
  preview: `https://${env("VITE_REAL_ESTATE")}.welcomeworkdays.dev`,
  prod: `https://${env("VITE_REAL_ESTATE")}.welcomeworkdays.com`,
};
export const APP_DOMAIN = ENV_DOMAINS[env("VITE_ENVIRONMENT") as Environment];

export const BASE_URL = `${env("VITE_API_BASE")}/api`;

export const APPEAR_ANIMATION = {
  from: { opacity: 0, y: 30 },
  to: { opacity: 1, y: 0 },
};
